import React, { useRef } from 'react'
import { View, TouchableOpacity, Platform } from 'react-native'
import shared from '../../styles/shared'
import InPageValidationErrors from '../InPageValidationErrors'

import { styles } from '../EntityEdit.styles'
import MSFESIcon from '../MSFESIcon'
import RecaptchaContainer from '../RecaptchaContainer'
import MSFESButton from '../MSFESButton'
import spacing from '../../styles/spacing'
export const ValidationAndButtonBar = ({
  definition,
  offline_id,
  identifierCurrentPage,
  shouldPaginate,
  hasNextStep,
  prevPage,
  currentPage,
  nextPage,
  requiresCaptchaFilled,
  fieldChanged,
  children,
  availablePages,
  hideButtons,
  isWizard,
  onLastPageOfWizard,
  onSaveEntityPressed,
  saveButtonLabel,
  loading,
  pageVisibilityRules = {},
  hasChanges,
  mode
}) => {
  const buttonPanelStyle = {
    flexDirection: 'row',
    flex: 1,
    flexWrap: 'wrap',
    justifyContent: 'center'
  }

  const recaptcha = useRef()

  const onVerified = (captcha) => {
    fieldChanged({ field_data: { property: 'captcha' } }, captcha, false)
    setTimeout(onSaveEntityPressed, 200)
  }

  const canGoBack =
    currentPage !== 1 && pageVisibilityRules[currentPage - 1] !== false

  return (
    <>
      <InPageValidationErrors
        definition={definition}
        offline_id={offline_id}
        step={identifierCurrentPage}
      />
      {shouldPaginate && hasNextStep && (
        <View style={[shared.mobileWidthView]}>
          <View style={buttonPanelStyle}>
            {canGoBack && (
              <TouchableOpacity
                style={styles.touchableChevron}
                onPress={prevPage}
              >
                <MSFESIcon name={'chevron-left'} />
              </TouchableOpacity>
            )}
            {hasNextStep && (
              <TouchableOpacity
                style={styles.touchableChevron}
                onPress={nextPage}
              >
                <MSFESIcon name={'chevron-right'} />
              </TouchableOpacity>
            )}
          </View>
        </View>
      )}
      {children}

      {(!hasNextStep || !shouldPaginate) && mode !== 'view' && (
        <View>
          {requiresCaptchaFilled && (
            <View style={{ marginTop: spacing.m2 }}>
              <RecaptchaContainer
                onVerified={onVerified}
                ref={recaptcha}
                onChange={(value) => {
                  fieldChanged(
                    { field_data: { property: 'captcha' } },
                    value,
                    false
                  )
                }}
              />
            </View>
          )}
        </View>
      )}
      {((!isWizard && !hideButtons && (!hasNextStep || !shouldPaginate)) ||
        onLastPageOfWizard) && (
        <View style={[shared.mobileWidthView]}>
          <View style={buttonPanelStyle}>
            {!hasNextStep && availablePages > 1 && shouldPaginate && (
              <TouchableOpacity
                style={styles.touchableChevron}
                onPress={prevPage}
              >
                <MSFESIcon name={'chevron-left'} />
              </TouchableOpacity>
            )}
            {mode !== 'view' && (
              <MSFESButton
                loading={loading}
                disabled={!hasChanges}
                title={saveButtonLabel}
                onPress={() => {
                  if (requiresCaptchaFilled && Platform.OS !== 'web') {
                    recaptcha.current?.open()
                  } else {
                    onSaveEntityPressed()
                  }
                }}
              />
            )}
          </View>
        </View>
      )}
    </>
  )
}
