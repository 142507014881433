import React, { useState, useMemo } from 'react'
import { View, Text } from 'react-native'
import MSFESLabel from './MSFESLabel'
import MSFESButton from './MSFESButton'
import colors from '../styles/colors'
import spacing from '../styles/spacing'
import YesNoCancelDialog from './YesNoCancelDialog'
import { isArray } from 'lodash'
import GlobalLoading from './GlobalLoading'

export const CommitPendingApprovalsBar = ({
  approveChanges,
  rejectChanges,
  property,
  value
}) => {
  const [rejectActionPending, setRejectActionPending] = useState(false)

  const [loading, setLoading] = useState(false)
  const onRejectChangesPressed = () => {
    setRejectActionPending(true)
  }

  const rejectCancelPressed = () => {
    setRejectActionPending(false)
  }

  const onRejectYesPressed = () => {
    setRejectActionPending(false)
    rejectChanges()
  }

  const displayableValue = useMemo(() => {
    if (isArray(value)) {
      return value?.map((v) => v.label)
    }

    return null
  }, [value])

  return (
    <View
      style={{
        flexDirection: 'row',
        padding: spacing.m1,
        backgroundColor: colors.approvalBackground
      }}
    >
      <View style={{ flex: 1 }}>
        <MSFESLabel label={`Approval Requested:`} />
        <Text style={{ color: 'black' }}>
          {displayableValue ?? JSON.stringify(value)}
        </Text>
      </View>
      {loading && <GlobalLoading size="small" color={colors.primary} />}
      <MSFESButton
        title={'Reject'}
        variant="danger"
        disabled={loading}
        small
        onPress={onRejectChangesPressed}
        style={{ marginRight: spacing.m1 }}
      />
      <MSFESButton
        title={'Approve'}
        variant="add"
        disabled={loading}
        onPress={() => {
          setLoading(true)
          approveChanges(property, value).finally(() => {
            setLoading(false)
          })
        }}
      />

      {rejectActionPending && (
        <YesNoCancelDialog
          title="Reject Changes"
          body="Are you sure you want to reject the selected changes? This is a permanent action."
          yesAction={onRejectYesPressed}
          noAction={rejectCancelPressed}
          cancelAction={rejectCancelPressed}
        />
      )}
    </View>
  )
}
