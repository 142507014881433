import React, { useEffect, useState } from 'react'
import { View, ScrollView } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import entityActions, { getFilterString } from '../../store/entity/actions'
import { shouldRefetch } from './RadioList'
import { sanitiseFilterString } from '../../libraries/sanitiseFilterString'
import { DataTable } from 'react-native-paper'
import { MSFESCheckbox } from '../MSFESCheckbox'

const useFetchEntitiesOfType = (entityType, args = {}) => {
  const { filterRule, needsFieldsToSort } = args
  const filter = filterRule || needsFieldsToSort ? { detailed: true } : null

  const filterString = sanitiseFilterString(getFilterString(null, filter))

  const dispatch = useDispatch()
  const entities = useSelector((state) => {
    return entityType?.name
      ? state?.entities?.[entityType?.name + filterString]?.data ?? []
      : []
  })

  useEffect(() => {
    if (shouldRefetch(entityType?.name)) {
      dispatch(
        entityActions.listEntity(
          { name: entityType?.name },
          { filter, page: 1, detailed: filter?.detailed }
        )
      )
    }
  }, [])

  return { data: entities }
}
const _RadioTable = (props, ref) => {
  const { editable = true } = props
  const { columnHeaders, rowHeaders } =
    props.data?.field?.field_data?.params ?? {}
  const { data: dataColumns } = useFetchEntitiesOfType({ name: columnHeaders })
  const { data: dataRows } = useFetchEntitiesOfType({ name: rowHeaders })

  const [localValue, setLocalValue] = useState(props.defaultValue)

  useEffect(() => {
    props.changeHandler?.(localValue)
  }, [localValue])

  const toggleCheckState = (row, column) => {
    setLocalValue((prev) => {
      const newValue = prev?.filter((item) => item.rowId !== row.id) ?? []
      newValue.push({
        rowId: row.id,
        rowDisplayName: row.display_name,
        columnId: column.id,
        columnDisplayName: column.display_name
      })
      return newValue
    })
  }

  const columnsToShow = dataColumns?.slice(0, 5)

  return (
    <View style={{ flex: 1 }}>
      <DataTable style={{ flex: 1 }}>
        <DataTable.Header>
          <DataTable.Title></DataTable.Title>
          {columnsToShow?.map((column) => (
            <DataTable.Title key={column.id}>
              {column.display_name}
            </DataTable.Title>
          ))}
        </DataTable.Header>
        <ScrollView>
          {dataRows?.slice(0, 50).map((row) => (
            <DataTable.Row key={row.id}>
              <DataTable.Cell>{row.display_name}</DataTable.Cell>
              {columnsToShow?.map((column) => (
                <DataTable.Cell key={column.id}>
                  <MSFESCheckbox
                    disabled={!editable}
                    checked={
                      localValue?.some(
                        (item) =>
                          item.rowId === row.id && item.columnId === column.id
                      ) ?? false
                    }
                    onPress={() => {
                      toggleCheckState(row, column)
                    }}
                  />
                </DataTable.Cell>
              ))}
            </DataTable.Row>
          ))}
        </ScrollView>
      </DataTable>
    </View>
  )
}
export const RadioTable = React.forwardRef(_RadioTable)
