// libraries

import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  View,
  Keyboard,
  InteractionManager,
  useWindowDimensions,
  Platform,
  Text
} from 'react-native'
import { Divider, Overlay } from 'react-native-elements'
import _ from 'lodash'
import {
  discoverPageTitle,
  getDisplayLabelForEntity
} from '../libraries/entityTools'

//actions
import entityActions, { dumpUnsavedEntity } from '../store/entity/actions'

// styles
import shared from '../styles/shared'

// components
import {
  buttonContainerTypes,
  EntityEdit,
  tileContainerTypes
} from '../components/EntityEdit'
import { TransitionManager } from '../components/TransitionManager'
import { VersionFiles } from '../components/VersionFiles'

import { websafeGoBack } from '../libraries/navigation'
import FlashMessage from 'react-native-flash-message'
import WithHeader, { headerTextStyle } from '../components/WithHeader'
import { KeyboardAvoidingView } from 'react-native'
import { ListItemLinkButton } from '../components/ListItemLinkButton'
import MSFESLabel from '../components/MSFESLabel'
import grid from '../styles/grid'
import GlobalLoading from '../components/GlobalLoading'
import YesNoCancelDialog from '../components/YesNoCancelDialog'
import EntityActions from '../components/EntityActions'
import { useLinkTo } from '@react-navigation/native'

import Mustache from 'mustache'
import spacing from '../styles/spacing'
import { downloadResponseAsJson } from '../components/EntityTypeActions'
import { v4 as uuidv4 } from 'uuid'
import { shouldShowDesktop, useIsDesktop } from '../libraries/shouldShowDesktop'
import EntityField from '../components/EntityField'
import EntityAddHeader from '../components/EntityAddHeader'
import { flattenEntity } from '../helpers/entity'
import EntityEditContext from '../contexts/EntityEditContext'
import { useDropdownHelper } from '../useDropdownHelper'
import PublicFormContext from '../contexts/PublicFormContext'
import { MSFESFooter } from '../components/MSFESFooter'
import TenantBrandHeader from '../components/TenantBrandHeader'
import { PagesDropdown } from '../components/dropdowns/PagesDropdown'
import { EntityLocalCache } from '../components/EntityLocalCache'
import { useDiscoverEntityEntityType } from '../hooks/useDiscoverEntityEntityType'
import {
  fieldIsAffectedByPageVisibilityRules,
  getFormulaBasedChanges,
  useDoVisibilityChecks
} from '../hooks/useDoVisibilityChecks'
import { PortalWrapper } from '../components/fields/DatePicker'
import { simplifyObject } from '../components/fields/SelectWithData'

const getFriendlyEventSignature = (eventSignature) => {
  let formattedEventSignature = eventSignature

  try {
    const eventSplit = eventSignature.split('_')
    const l = eventSplit.length

    const itemsBetween = eventSplit.slice(2, l - 1).join(' ')

    formattedEventSignature =
      (eventSplit[0] ? eventSplit[0] + '/' : '') +
      eventSplit[1] +
      '/' +
      itemsBetween +
      '/' +
      eventSplit[l - 1]
  } catch (e) {
    // don't care.
  }

  return formattedEventSignature
}
const getBareEntity = (
  entityType,
  mode,
  storedEntity = null,
  virtualEntity = null
) => {
  const newBareEntity = {
    ...{
      type: entityType,
      mode: mode,
      files: [],
      offline_id: uuidv4(),
      is_quick_mode: true,
      is_pristine: true,
      started_at: Date.now()
    },
    ...storedEntity,
    ...virtualEntity
  }

  return newBareEntity
}

export const addMode = 'add'
export const updateMode = 'edit'
export const viewMode = 'view'

const discoverPrepopulatedFields = (route) => {
  const prepopulatedFields = _.merge(
    ..._.map(route.params, (value, key) => {
      if (value !== undefined) {
        if (key.indexOf('field.') !== -1) {
          return { [key.substr('field.'.length)]: value }
        }
        return null
      }
    }).filter((param) => param)
  )

  return prepopulatedFields
}

function EntityAddComponent(props) {
  const {
    currentPage,
    withTenantRecord = null,
    onUpdatePage,
    route,
    navigation,
    isInSplitView = false,
    saveWithTaskLevelValidation = false /* task level validation does not require most fields on create. */,
    onCloseSplit,
    onModifySchedulePressed = null,
    onDeleteSchedulePressed = null,
    onEditToggled,
    virtualEntity = null,
    savedCallback,
    beginsWithEntity = null,
    isNestedInsideFieldName = null
  } = props

  const modalFlashRef = useRef()
  const scrollRef = useRef()
  const dispatch = useDispatch()

  const publicFormContext = useContext(PublicFormContext)
  const mode = route.params.mode

  const storedEntity = useSelector(
    (state) => {
      const id = route?.params?.id
      if (id) {
        const storedEntity = _.get(state, `entities.byId[${id}]`, null)
        return storedEntity
      }
      return null
    },
    (prev, next) => prev.updated_at === next.updated_at
  )

  const { current_user, netInfoType } = useSelector((state) => {
    return {
      netInfoType: state.app?.netInfo?.type,
      current_user: state?.users?.user,
      errors: state?.entities?.errors?.errors
    }
  })

  const { entityType, isFetching } = useDiscoverEntityEntityType(
    route,
    storedEntity
  )

  const navState = props.isPublicForm ? null : navigation?.dangerouslyGetState()
  const linkTo = useLinkTo()
  const [hasChanges, setHasChanges] = useState(false)

  const onCloseSplitWrapped = () => {
    if (hasChanges) {
      setSaveActionPending('backPressed')
    } else {
      onCloseSplit?.()
    }
  }
  const [pageHeading, setPageHeading] = useState('')
  const [entity, updateLocalEntity] = useState(
    getBareEntity(
      entityType,
      mode,
      beginsWithEntity || storedEntity,
      virtualEntity
    )
  )

  useEffect(() => {
    const title = discoverPageTitle(entityType, mode, entity)
    setPageHeading(title)
    // navigation?.setOptions && navigation.setOptions({ title })
  }, [entityType, entity.display_name, route.params.type, mode])

  const windowDimensions = useWindowDimensions()

  const showDesktop = shouldShowDesktop(windowDimensions)

  const entityFlattened = useMemo(() => {
    // also matches withObjectDataFlat in Entity.php
    const entityFlattened = flattenEntity(entity, false)

    return entityFlattened
  }, [entity])
  const entityChanged = (newEntityPartial, isUserInitiated) => {
    let didMakeChange = false
    Object.keys(newEntityPartial).map((key) => {
      const newVal = _.get(newEntityPartial, key, null)
      const oldVal = _.get(entity, key, null)
      if (newVal !== oldVal) {
        didMakeChange = true
      }
    })
    if (isUserInitiated && didMakeChange) {
      setHasChanges(true)
      newEntityPartial.is_pristine = false
      newEntityPartial.touched_at = Date.now()
    }

    updateLocalEntity((entity) => {
      const newMergedEntity = {
        ...entity,
        ...newEntityPartial
      }

      InteractionManager.runAfterInteractions(() => {
        isUserInitiated &&
          dispatch(entityActions.updateEditing(newMergedEntity, entityType))
      })

      return newMergedEntity
    })
  }

  const {
    pageVisibilityRules,
    fieldVisibilityRules,
    filterRulesRequiredFromEffects
  } = useDoVisibilityChecks({
    entity,
    entityType,
    entityChanged,
    publicFormContext
  })

  useEffect(() => {
    const changes = getFormulaBasedChanges(entityFlattened, entityType)
    if (changes.length) {
      let newEntityPartial = {}
      let hasChangesToApply = false

      changes.map(({ field, result }) => {
        if (entity[field.field_data.property] != '' + result) {
          newEntityPartial[field.field_data.property] = '' + result
          hasChangesToApply = true
        }
      })

      if (hasChangesToApply) {
        entityChanged?.(newEntityPartial, false)
      }
    }
  }, [entity, entityType, publicFormContext?.public])

  useEffect(() => {
    updateLocalEntity((localEntity) => {
      const newObject = {
        ...localEntity,
        ...(storedEntity ? { offline_id: undefined } : null),
        ...storedEntity
      }

      return newObject
    })
  }, [storedEntity])

  const [transitionLoading, setTransitionLoading] = useState(false)
  const [entityLoading, setEntityLoading] = useState(false)

  const prepopulatedFields = useMemo(() => {
    return discoverPrepopulatedFields(route)
  }, [route.params])

  const fetchAndUpdateLocalEntity = (entityType, id) => {
    return dispatch(entityActions.showEntity({ type: entityType, id }))
  }
  useEffect(() => {
    updateLocalEntity((entity) => {
      return { ...entity, ...prepopulatedFields }
    })
  }, [
    route.params.id,
    route.params.type,
    prepopulatedFields,
    current_user?.current_tenant,
    ''
  ])

  useEffect(() => {
    const id = parseInt(route.params.id)
    if (id && entityType) {
      updateLocalEntity(getBareEntity(entityType, mode, storedEntity))
      fetchAndUpdateLocalEntity(entityType, id)
    }
  }, [entityType, route.params.id])

  const { hasPlaces, shouldShowTransitions } = useMemo(() => {
    const hasPlaces = _.get(entityType, 'object_data.has_places', true)
    const shouldShowTransitions =
      _.get(entityType, 'object_data.show_status', false) ||
      _.get(entityType, 'is_task', false)

    return { hasPlaces, shouldShowTransitions }
  }, [entityType, route.params.id])

  const onCancelEntityPressed = () => {
    if (hasChanges) {
      setSaveActionPending('backPressed')
    } else {
      onCloseSplit?.()
    }
  }
  const handleOfflineSaveNotify = () => {
    modalFlashRef.current?.showMessage({
      message:
        'Your device appears to be offline. The entry has been saved and will be uploaded to the server when you reconnect.',
      type: 'danger',
      duration: 10000
    })
    setEntityLoading(false)
    setHasChanges(false)
    navigation.setParams({ mode: viewMode })
  }

  const saveEntity = (args) => {
    const { showLoading, showToasts, withPartial, mode } = args ?? {
      showLoading: true,
      showToasts: true,
      mode: route.params?.mode ?? addMode,
      withPartial: {}
    }

    const useTaskLevelValidation =
      saveWithTaskLevelValidation && mode === addMode

    const fullEntity = {
      ...entity,
      ...withPartial,
      type: simplifyObject(entity?.type)
    }

    // what does this block do?
    // for all data saving in the fullEntity, I need to check some field types.
    // if it is a string field, it should not be a complex object.
    // if it is, grab the display_value.
    // here we can add other overrides for fields that have switched types.
    // HRFIR-825.
    Object.keys(fullEntity).forEach((key) => {
      const field = entityType.fields.find(
        (f) => f.field_data?.property === key
      )
      if (field) {
        if (field.field_data?.type === 'single-text') {
          if (typeof fullEntity[key] === 'object') {
            if (fullEntity[key]?.display_value) {
              console.warn(
                'on save overload:',
                fullEntity[key],
                'to',
                fullEntity[key].display_value
              )
              fullEntity[key] = fullEntity[key].display_value
            }
          }
        }
      }
    })

    showLoading && setEntityLoading(true)

    const waitPromise = new Promise((resolve) => {
      setTimeout(() => {
        resolve(true)
      }, 150)
    })
    return waitPromise
      .then(async () => {
        // upload media.

        await dispatch(entityActions.clearGeneralError())
        // stores the whole save operation in the queue - files and the entity record.

        await dispatch(
          entityActions.queueEntitySave(
            fullEntity,
            entityType,
            mode,
            useTaskLevelValidation
          )
        )

        if (netInfoType === 'none') {
          // user is definitely offline.
          handleOfflineSaveNotify()
        } else {
          // this can fail if we are offline, and will stay in the queue for later.
          return dispatch(
            entityActions.processEntityOnQueue(
              fullEntity,
              undefined,
              publicFormContext.public
            )
          )
            .then((response) => {
              showToasts &&
                modalFlashRef.current?.showMessage({
                  message: 'Updated Successfully',
                  type: 'success'
                })

              setHasChanges(false)

              // we're obviously online, so process the rest of the queue as well.
              dispatch(entityActions.processEntireEntityQueue())

              return response
              // navigation.replace(`EntityList`, { type: entityType.name })
            })
            .catch((errors) => {
              console.warn('Save Entity Errors', errors)

              showToasts &&
                modalFlashRef.current?.showMessage({
                  message: 'Errors occurred during save: ' + errors,
                  type: 'danger'
                })
              throw errors
            })
            .finally(() => {
              setEntityLoading(false)
            })
        }
      })
      .catch((e) => {
        if (e.message == 'Failed to fetch') {
          handleOfflineSaveNotify()
        } else {
          let message = [e.message]
          try {
            const keys = Object.keys(e.errors)

            keys.map((k) => {
              message.push(e.errors[k])
            })
          } catch {}

          // when in interactive mode, and we have had a server response, we don't need to leave this on the queue.
          dispatch(entityActions.removeEntityFromQueue(entity))

          if (e?.message?.indexOf('given data') !== -1) {
            showToasts &&
              modalFlashRef.current?.showMessage({
                message:
                  'There were errors when saving. Please correct to the validation errors to continue.',
                type: 'danger'
              })
          }

          showLoading && setEntityLoading(false)
        }

        throw e
      })
  }
  const onSaveEntityPressed = async () => {
    Keyboard.dismiss()

    InteractionManager.runAfterInteractions(() => {
      return saveEntity()
        .then((response) => {
          savedCallback && savedCallback(response, fetchAndUpdateLocalEntity)

          // websafeGoBack(navigation);
        })
        .catch(() => {
          // TODO: break down validation to page level
          // so we can display it 'in-page'
        })
    })
  }

  const entityTransitionRequested = (
    entity,
    definition,
    transitionRequested
  ) => {
    setTransitionLoading(true)
    dispatch(
      entityActions.requestTransition(entity, definition, transitionRequested)
    )
      .then(() => {
        modalFlashRef.current?.showMessage({
          message: 'Status Updated Successfully',
          type: 'success'
        })

        const { id } = entity
        fetchAndUpdateLocalEntity(entityType, id)
      })
      .catch(() => {})
      .finally(() => {
        setTransitionLoading(false)
      })
  }

  const toggleWatching = () => {
    updateLocalEntity((entity) => {
      return { ...entity, watching: !entity.watching }
    })

    dispatch(entityActions.toggleWatch({ entity, entityType })).then(
      (response) => {
        updateLocalEntity((entity) => {
          return { ...entity, watching: response.data.watching }
        })
      }
    )
  }

  const [overlayState, setOverlayState] = useState(false)
  const toggleExtraSettingsOverlay = useCallback(() => {
    setOverlayState(!overlayState)
  }, [overlayState])

  const onDeletePressed = () => {
    setOverlayState(false)
    setDeleteActionPending(true)
  }

  const _onEditToggled = () => {
    toggleExtraSettingsOverlay()

    switch (mode) {
      case viewMode:
        if (onEditToggled) {
          onEditToggled(updateMode)
        } else {
          navigation.setParams({ mode: updateMode })
        }
        break
      case updateMode:
        if (hasChanges) {
          setSaveActionPending('cancelEdit')
        } else {
          if (onEditToggled) {
            onEditToggled(viewMode)
          } else {
            navigation.setParams({ mode: viewMode })
          }
        }

        break
    }
  }

  const noActionEditing = () => {
    dispatch(dumpUnsavedEntity({ entity }))
    onCloseSplit?.()
  }

  const noActionBackButton = () => {
    setSaveActionPending(false)
    setHasChanges(false)
    dispatch(dumpUnsavedEntity({ entity }))
    onCloseSplit ? onCloseSplit() : websafeGoBack(navigation, navState, linkTo)
  }

  const yesActionEditing = () => {
    setSaveActionPending(false)
    saveEntity()
      .then(() => {
        navigation.setParams({ mode: viewMode })
      })
      .catch(() => {})
  }

  const yesActionBackButton = () => {
    setSaveActionPending(false)
    saveEntity()
      .then(() => {
        websafeGoBack(navigation, navState, linkTo)
      })
      .catch(() => {})
  }

  const deleteCancelPressed = () => {
    setDeleteActionPending(false)
  }

  const deleteConfirmPressed = () => {
    return dispatch(entityActions.deleteEntity(entity, entityType))
      .then(() => {
        modalFlashRef.current?.showMessage({
          message: 'Deleted Successfully',
          type: 'success'
        })

        setDeleteActionPending(false)
        setHasChanges(false)
        entityActions.listEntity(entityType)

        navigation?.replace(`EntityList`, { type: entityType.name })
      })
      .catch((errors) => {
        console.warn('Delete Entity Errors', errors)
        modalFlashRef.current?.showMessage({
          message: errors.message,
          type: 'danger'
        })
        throw errors
      })
      .finally(() => {
        setEntityLoading(false)
      })
  }

  const canViewVersionFiles = useMemo(() => {
    return _.get(
      current_user,
      `effective_permissions['view||name:version-pdfs']`,
      false
    )
  }, [current_user])

  const canModifyThisEntityType = useMemo(() => {
    const entityIsGlobal = !entity?.tenant_id && !route.params?.tenant_id
    const userIsGlobal = !current_user?.current_tenant
    const userIsLocalAndEditingGlobal = !userIsGlobal && entityIsGlobal

    const hasEditPermission = entityType
      ? _.get(
          current_user,
          `effective_permissions['update|id:${entityType.id}|name:${entityType.name}']`,
          false
        )
      : false

    const myEntityId = _.get(current_user, 'personnel_record.id', null)
    const thisEntityIsMyself = myEntityId == entity?.id

    const canModifyThisEntityType =
      entityType &&
      !userIsLocalAndEditingGlobal &&
      (hasEditPermission || thisEntityIsMyself)

    return canModifyThisEntityType
  }, [
    current_user,
    entityType,
    entity?.tenant_id,
    entity?.id,
    route.params?.tenant_id
  ])

  const [saveActionPending, setSaveActionPending] = useState(false)
  const [deleteActionPending, setDeleteActionPending] = useState(false)
  const [viewVersions, setViewVersions] = useState(false)

  const onViewVersions = () => {
    setViewVersions(true)
  }

  const onExportJSONFile = () => {
    const filename =
      entityType.name + '_' + entity.display_name + '_' + entity.id + '.json'
    downloadResponseAsJson(entity, filename)
  }

  const onBackButtonPressed = () => {
    if (hasChanges) {
      setSaveActionPending('backPressed')
    } else {
      noActionBackButton()
    }
  }

  const orderedTileFields = useMemo(() => {
    if (entityType?.fields) {
      return _.chain(entityType?.fields ?? [])
        .filter((f) => tileContainerTypes.includes(f.field_data.type))
        .sortBy((f) => _.get(f, 'field_data.sort_order', 999))
        .sortBy((f) => _.get(f, 'field_data.page', 1))

        .value()
    }
    return []
  }, [entityType?.fields])

  const orderedButtonFields = useMemo(() => {
    return _.chain(entityType?.fields ?? [])
      .filter((f) => buttonContainerTypes.includes(f.field_data.type))
      .sortBy((f) => _.get(f, 'field_data.sort_order', 999))
      .sortBy((f) => _.get(f, 'field_data.page', 1))

      .value()
  }, [entityType?.fields])

  const [visibleFields, setVisibleFields] = useState(null)
  const [scrollPosition, setScrollPosition] = useState(0)
  const handleScroll = (event) => {
    setScrollPosition(event.nativeEvent.contentOffset.y)
  }

  const updateScrollPosition = (newPosition) => {
    scrollRef.current?.scrollTo?.({ y: newPosition, animated: false })
  }

  const [
    dropdownVisibilityState,
    ,
    toggleDropdownState,
    buttonRef,
    dropdownOverlayStyle
  ] = useDropdownHelper(250)

  const rightButtonProps = {
    rightButtonAction: toggleDropdownState,
    rightButtonIcon: 'bars',
    rightButtonRef: buttonRef
  }

  const tenantRecordInUse =
    withTenantRecord ?? publicFormContext?.entityType?.tenant_record

  const [isMobileOverlayVisible, setIsMobileOverlayVisible] = useState(false)
  const [allPages, setAllPages] = useState(null)
  const [currentPageIndex, setCurrentPageIndex] = useState(null)

  const isDesktop = useIsDesktop()

  const subtitleValue = Mustache.render(
    `${entityType?.object_data?.subtitle ?? ''}`,
    {
      ...flattenEntity(entity, false)
    }
  )

  const quickAddFields = useMemo(() => {
    if (entityType?.fields) {
      return (entityType?.fields ?? [])
        .filter((f) => f.field_data?.flags?.quickAddField)
        .filter((f) => {
          const result = !fieldIsAffectedByPageVisibilityRules(
            f,
            pageVisibilityRules
          )
          return result
        })
    }

    return false
  }, [entityType?.fields, pageVisibilityRules])

  const toggleQuickAdd = () => {
    updateLocalEntity((entity) => ({
      ...entity,
      is_quick_mode: !entity.is_quick_mode
    }))
  }
  const pageDropdownComponent = (
    <PagesDropdown
      visible={true}
      canModifyThisEntityType={canModifyThisEntityType}
      visibleFields={visibleFields}
      pages={allPages}
      displayPicture={entity.SYSTEM_display_picture}
      title={pageHeading}
      isQuickAdd={mode === addMode && entity.is_quick_mode}
      onQuickAddToggled={toggleQuickAdd}
      hasQuickAddAvailable={!!quickAddFields.length}
      entityTypeLabel={subtitleValue ?? entityType?.label}
      offline_id={entity?.offline_id}
      value={currentPageIndex + 1}
      onDismiss={() => {
        setIsMobileOverlayVisible(false)
      }}
      mode={mode}
      hasChanges={hasChanges}
      onEditToggled={_onEditToggled}
      onSaveEntityPressed={onSaveEntityPressed}
      onPageChanged={(newPage, index) => {
        setScrollPosition(0)
        onUpdatePage(index)
      }}
    />
  )

  const children = (
    <>
      <EntityEditContext.Provider
        value={[
          scrollPosition,
          setScrollPosition,
          updateScrollPosition,
          saveEntity,
          allPages,
          setAllPages,
          currentPageIndex,
          setCurrentPageIndex,
          isMobileOverlayVisible,
          setIsMobileOverlayVisible,
          visibleFields,
          setVisibleFields,
          filterRulesRequiredFromEffects,
          isNestedInsideFieldName,
          { hasChanges }
        ]}
      >
        <EntityActions
          mode={mode}
          visibleFields={visibleFields}
          allPages={allPages}
          displayName={entity.display_name}
          dropdownOverlayStyle={dropdownOverlayStyle}
          onDeleteSchedulePressed={
            virtualEntity && onDeleteSchedulePressed
              ? onDeleteSchedulePressed
              : undefined
          }
          onModifySchedulePressed={
            (virtualEntity || entity.virtual_event_id) &&
            onModifySchedulePressed
              ? onModifySchedulePressed
              : undefined
          }
          onViewVersions={onViewVersions}
          onExportJSONFile={onExportJSONFile}
          id={entity.id}
          tenant_id={route.params?.tenant_id}
          isVisible={dropdownVisibilityState}
          entityType={entityType}
          canViewVersionFiles={canViewVersionFiles}
          versions={entity.versions}
          onBackdropPress={toggleDropdownState}
          onEditToggled={_onEditToggled}
          onDeletePressed={onDeletePressed}
          onToggleWatching={toggleWatching}
          watching={entity.watching}
          editing={mode === updateMode}
        />

        {entity.parent && (
          <View
            style={{
              paddingHorizontal: grid.padding.viewPort.paddingHorizontal
            }}
          >
            <MSFESLabel label={'Belongs To'} />
            <ListItemLinkButton
              entity={entity.parent}
              title={getDisplayLabelForEntity(entity.parent)}
              key={entity.parent.id}
              to={entity.parent.uri}
            />
          </View>
        )}

        <KeyboardAvoidingView
          behavior={Platform.OS === 'ios' ? 'padding' : null}
          style={[
            shared.debugOff,
            {
              flex: 1,
              flexDirection: 'column',
              justifyContent: 'center'
            }
          ]}
          keyboardVerticalOffset={Platform.OS === 'ios' ? 120 : 0}
        >
          <View style={{ flexDirection: 'row', flex: 1 }}>
            {!isDesktop && isMobileOverlayVisible && (
              <PortalWrapper>
                <Overlay
                  onBackdropPress={() => setIsMobileOverlayVisible(false)}
                >
                  <View
                    style={[
                      {
                        height: 400,
                        flexDirection: 'column',
                        maxHeight: 500,
                        minWidth: 220
                      }
                    ]}
                  >
                    {pageDropdownComponent}
                  </View>
                </Overlay>
              </PortalWrapper>
            )}
            {isDesktop && (
              <View style={{ minWidth: 220 }}>{pageDropdownComponent}</View>
            )}
            {entityType && isFetching && <GlobalLoading />}
            {entityType && (
              <View
                style={{
                  flexDirection: 'column',
                  flex: 1,
                  borderTopWidth: 2,
                  borderTopColor: '#eee'
                }}
              >
                <View
                  style={{ flex: 1 }}
                  keyboardShouldPersistTaps={'always'}
                  ref={scrollRef}
                  onScroll={handleScroll}
                  scrollEventThrottle={64}
                  overScrollMode={
                    'never' /* part of crash fix https://github.com/facebook/react-native/issues/33083 */
                  }
                >
                  {/* <Text style={{ fontSize: 9 }}>{JSON.stringify(DEBUG_ENTITY)}</Text> */}
                  <View
                    style={[
                      shared.gridWidthView,
                      {
                        width: '100%',
                        flex: 1
                      },
                      props.style
                    ]}
                  >
                    {tenantRecordInUse && (
                      <TenantBrandHeader tenant={tenantRecordInUse} />
                    )}
                    {publicFormContext.public && (
                      <View>
                        <View style={{ paddingTop: spacing.m2 }}>
                          <Text style={headerTextStyle}>
                            {entityType?.label}
                          </Text>
                        </View>
                        <View
                          style={{
                            marginTop: spacing.m3,
                            marginBottom: spacing.m3
                          }}
                        >
                          <Divider />
                        </View>
                      </View>
                    )}
                    <EntityLocalCache entity={entity} />
                    {entity.virtual_event_signature && (
                      <View
                        style={{
                          paddingHorizontal: spacing.m1
                        }}
                      >
                        <MSFESLabel
                          helpText={entity.virtual_event_signature}
                          label={
                            'Created by Recurring Event: ' +
                            getFriendlyEventSignature(
                              entity.virtual_event_signature
                            )
                          }
                        />
                      </View>
                    )}
                    <EntityEdit
                      key={
                        mode + entity.id
                      } /* Used to throw this away when toggling modes. */
                      mode={mode}
                      entity={entity}
                      prepopulatedFields={prepopulatedFields}
                      definition={entityType}
                      entityChanged={entityChanged}
                      onUpdatePage={onUpdatePage}
                      currentPage={currentPage}
                      onCancelEntityPressed={onCancelEntityPressed}
                      onSaveEntityPressed={onSaveEntityPressed}
                      fieldVisibilityRules={fieldVisibilityRules}
                      pageVisibilityRules={pageVisibilityRules}
                    >
                      {hasPlaces && shouldShowTransitions && (
                        <TransitionManager
                          entity={entity}
                          mode={mode}
                          definition={entityType}
                          transitionRequested={entityTransitionRequested}
                          loading={transitionLoading}
                        />
                      )}
                    </EntityEdit>

                    {showDesktop && <MSFESFooter />}
                  </View>
                </View>
              </View>
            )}
            {!entityType && !isFetching && (
              <View>
                <Text>Entity Type not found.</Text>
              </View>
            )}
            <View
              style={{ flexDirection: 'column' }}
              testID="ordered-tile-fields"
            >
              {!!orderedTileFields.length && isInSplitView && (
                <View
                  style={{
                    flex: 1
                  }}
                >
                  {orderedTileFields.map((field, index) => {
                    return (
                      <EntityField
                        style={{ flex: 1, borderRadius: 0 }}
                        mode={viewMode}
                        entity={entity}
                        definition={entityType}
                        name={field.field_data.property}
                        renderStaticHeader={false}
                        prepopulatedFieldValue={
                          prepopulatedFields
                            ? prepopulatedFields[field.field_data.property]
                            : undefined
                        }
                        field={field}
                        fileAppended={() => {}}
                        fieldChanged={() => {}}
                        key={index + '-' + field.id}
                      />
                    )
                  })}
                </View>
              )}
            </View>
          </View>
        </KeyboardAvoidingView>
        {entityLoading && <GlobalLoading />}
      </EntityEditContext.Provider>
    </>
  )

  const buttons =
    orderedButtonFields.length &&
    orderedButtonFields.map((field, index) => {
      return (
        <EntityField
          style={{ flex: 1 }}
          mode={viewMode}
          entity={entity}
          definition={entityType}
          name={field.field_data.property}
          renderStaticHeader={false}
          supportsInputShading={false}
          prepopulatedFieldValue={
            prepopulatedFields
              ? prepopulatedFields[field.field_data.property]
              : undefined
          }
          field={field}
          fileAppended={() => {}}
          fieldChanged={() => {}}
          key={index + '-' + field.id}
        />
      )
    })

  return (
    <>
      {!publicFormContext.public && (
        <>
          {showDesktop && (
            <EntityAddHeader
              mode={mode}
              dropdownVisibilityState={dropdownVisibilityState}
              title={pageHeading}
              entityTypeLabel={subtitleValue ?? entityType?.label}
              entityType={entityType}
              entity={entity}
              buttons={buttons}
              displayPicture={entity.SYSTEM_display_picture}
              onClose={onCloseSplitWrapped}
              {...rightButtonProps}
              navigation={navigation}
            >
              {children}
            </EntityAddHeader>
          )}
          {!showDesktop && (
            <WithHeader
              title={pageHeading + (hasChanges ? '*' : '')}
              setPageTitle={false}
              isInSplitView={isInSplitView}
              dropdownVisibilityState={dropdownVisibilityState}
              buttons={buttons}
              splitViewShowHeader={true}
              onCloseSplit={onCloseSplitWrapped}
              navigation={navigation}
              leftButtonType="back"
              backButtonAction={onBackButtonPressed}
              {...rightButtonProps}
            >
              {children}
            </WithHeader>
          )}

          {viewVersions && (
            <VersionFiles
              entity={entity}
              definition={entityType}
              onHideVersions={() => setViewVersions(false)}
            />
          )}
        </>
      )}
      {publicFormContext.public && <>{children}</>}

      {deleteActionPending && (
        <YesNoCancelDialog
          title="Delete"
          body="Are you sure you want to delete? This is a permanent action."
          yesAction={deleteConfirmPressed}
          noAction={deleteCancelPressed}
          cancelAction={deleteCancelPressed}
        />
      )}
      {saveActionPending && (
        <YesNoCancelDialog
          title="Unsaved Changes"
          body="Changes have been made. Would you like to save first?"
          yesAction={
            saveActionPending === 'backPressed'
              ? yesActionBackButton
              : yesActionEditing
          }
          noAction={
            saveActionPending === 'backPressed'
              ? noActionBackButton
              : noActionEditing
          }
          cancelAction={() => {
            setSaveActionPending(false)
          }}
        />
      )}
      <FlashMessage
        ref={modalFlashRef}
        canRegisterAsDefault={false}
        position="top"
        floating={true}
      />
    </>
  )
}

export default EntityAddComponent
