import React from 'react'
import colors from '../styles/colors'
import { Text, StyleSheet, TouchableOpacity } from 'react-native'
import spacing from '../styles/spacing'
export const ContextBanner = ({ label }) => {
  return (
    <TouchableOpacity style={style.notificationBar}>
      <Text style={style.notificationBarHeading}>{label}</Text>
    </TouchableOpacity>
  )
}

const style = StyleSheet.create({
  notificationBar: {
    paddingHorizontal: spacing.m2,
    paddingVertical: spacing.m1,
    backgroundColor: colors.brightOrange,
    flexDirection: 'row'
  },
  notificationBarQueuedUploads: {
    paddingHorizontal: spacing.m2,
    paddingVertical: spacing.m1,
    backgroundColor: colors.lightOrange,
    flexDirection: 'row'
  },
  notificationBarHeading: {
    color: colors.blueDarkest,
    fontSize: 16,
    flex: 1
  },
  view: {
    paddingHorizontal: spacing.m2,
    paddingVertical: spacing.m1,
    backgroundColor: colors.validationErrorBackground
  },
  text: {},
  textDate: {
    fontSize: 10
  },
  heading: {
    fontSize: 16,
    fontWeight: 'bold'
  }
})
