import React, { useCallback, useMemo } from 'react'
import CurrentlyNoItems from '../../components/CurrentlyNoItems'
import _ from 'lodash'
import { sortListWithEntityType } from '../../libraries/entityTools'

import { useSelector } from 'react-redux'
import {
  FlatList,
  RefreshControl,
  View,
  useWindowDimensions
} from 'react-native'
import { currentlyNoTemplateDefaults } from '../CategoryViewScreen'
import { GridItemEntity } from '../../components/GridItemEntity'
import { ListItemEntity } from '../../components/ListItemEntity'
import { viewMode } from '../EntityAddComponent'
import { useDiscoverEntityEntityType } from '../../hooks/useDiscoverEntityEntityType'

const DataListWrapper = ({
  sourceKey,
  entityType,
  refreshing,
  onRefresh,
  loadMoreItems,
  activeId,
  splitviewNavigate,
  filtersVisible,
  children
}) => {
  //TODO: refactor out this common functionality that is also in datatablewrapper.
  const entityListWrapper = useSelector((state) => {
    const entityListWrapper = entityType
      ? _.get(state.entities, sourceKey, {
          data: null,
          meta: null,
          hasSeenData: false
        })
      : { data: null, meta: null, hasSeenData: false }
    entityListWrapper.data = sortListWithEntityType(
      entityListWrapper.data,
      entityType
    )
    return entityListWrapper
  })

  const gridOrList = useSelector((state) => {
    return _.get(state.app, 'gridOrList', 'grid')
  })

  const { data: entityListItems } = entityListWrapper

  const { width } = useWindowDimensions()
  const columns = Math.round(width / 140)

  const renderAsGrid = gridOrList === 'grid'

  const flatlistKey = renderAsGrid ? columns : 1

  const currentlyNoLabel = useMemo(() => {
    return currentlyNoTemplateDefaults({ label: entityType?.label })
  }, [entityType])

  const renderItem = useCallback(
    (item, entityType, index, gridOrList, columns) => {
      const active = activeId == item.id

      const alarmStateIndex = item._fieldsInAlarmState?.length
        ? item._fieldsInAlarmState[0]
        : undefined

      const alarmStateColor = alarmStateIndex
        ? item._alarmStateColors?.[0] ?? 'red'
        : null
      if (renderAsGrid) {
        return (
          <GridItemEntity
            entity={item}
            alarmColor={alarmStateColor}
            active={active}
            style={{ flex: 1 / columns }}
            entityType={entityType}
            key={index}
            onPress={() => {
              splitviewNavigate(item, viewMode)
            }}
          />
        )
      } else {
        return (
          <ListItemEntity
            entity={item}
            alarmColor={alarmStateColor}
            active={active}
            entityType={entityType}
            key={index}
            onPress={() => {
              splitviewNavigate(item, viewMode)
            }}
          />
        )
      }
    },
    [entityType, activeId, gridOrList]
  )
  // console.log("LIST ITEMS", entityListItems)

  const entityTypes = useSelector((AppState) => AppState.entityTypes.data)

  const filteredEntityListItems = useMemo(() => {
    if (!filtersVisible?.length) {
      return entityListItems
    }
    return entityListItems.filter((item) => {
      const entityType = entityTypes.find(
        (entityType) => entityType.id === item.report_entity_type.id
      )
      return filtersVisible.some((filter) =>
        entityType?.object_data.visible_on_calendar
          ?.map((calendar) => calendar.id)
          .includes(filter.id)
      )
    })
  }, [entityListItems, filtersVisible])

  return (
    <>
      {!!filtersVisible?.length &&
        entityListItems.map((listItem) => {
          return (
            <EntityTypeLoader
              key={listItem.id}
              entityTypeId={listItem?.report_entity_type.id}
            />
          )
        })}
      {entityListItems && [
        <FlatList
          ListHeaderComponent={<View>{children}</View>}
          numColumns={flatlistKey}
          key={flatlistKey + ' ' + gridOrList}
          style={[{ backgroundColor: '#fafafa' }]}
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
          }
          extraData={activeId} /* resets the dragged item */
          ListFooterComponent={
            <View style={{ height: 0, marginBottom: 30 }}></View>
          }
          onEndReached={loadMoreItems}
          onEndReachedThreshold={1}
          ListEmptyComponent={
            refreshing ? null : <CurrentlyNoItems label={currentlyNoLabel} />
          }
          keyExtractor={(item) => item.id.toString()}
          data={filteredEntityListItems}
          renderItem={({ item, index }) => {
            return renderItem(item, entityType, index, gridOrList, columns)
          }}
        />
      ]}
    </>
  )
}

const EntityTypeLoader = ({ entityTypeId }) => {
  /* Ensures the entity type is loaded for the above.
  A little heavy handed, but if it fires multiple times it will be batched in getEntityType, */
  useDiscoverEntityEntityType(null, null, null, entityTypeId)
  return null
}

export default DataListWrapper
