import React from 'react'
import { View, ActivityIndicator } from 'react-native'
import spacing from '../styles/spacing'
import { Overlay } from 'react-native-elements'
export default function GlobalLoading({ size = 'large', color = 'blue' }) {
  const height = 256

  return (
    <Overlay
      overlayStyle={{
        shadowColor: 'transparent',
        shadowOpacity: 0,
        elevation: 0,
        backgroundColor: 'transparent'
      }}
      backdropStyle={{ backgroundColor: 'white', opacity: 0.75 }}
    >
      <View
        style={{
          marginTop: spacing.m4,
          height,
          top: 0,
          left: 0,
          width: height,
          backgroundColor: 'transparent'
        }}
      >
        <ActivityIndicator size={size} color={color} />
      </View>
    </Overlay>
  )
}
