import { Platform } from 'react-native'
import api from '../../data/api'
import fileActions from '../file/actions'
import _ from 'lodash'
import { showMessage } from 'react-native-flash-message'
import { DateTime } from 'luxon'
import Geolocation from '@react-native-community/geolocation'
const promiseRepository = {}
const clearGeneralError = () => {
  return {
    type: 'entity/clear-general-error'
  }
}

const clearFieldErrors = () => {
  return {
    type: 'entity/clear-field-errors'
  }
}

export const doPull = ({ id, entityType, fieldName, count }) => {
  return (dispatch) => {
    const routeUsed =
      getRouteForEntityDefinition({ entityDefinitions: entityType }) +
      '/' +
      id +
      '/pull/' +
      fieldName

    return api
      .post(routeUsed, { count })
      .then(async (response) => {
        dispatch({
          type: 'entity/show-success',
          response: { data: response.entity },
          id
        })
        return response
      })
      .catch((error) => {
        throw error
      })
  }
}

export const rejectChanges = ({ entity, entityType, pendingRejections }) => {
  const { id } = entity
  return (dispatch) => {
    const routeUsed =
      getRouteForEntityDefinition({ entityDefinitions: entityType }) +
      '/' +
      id +
      '/reject'

    return api
      .post(routeUsed, { pendingRejections })
      .then(async (response) => {
        dispatch({
          type: 'entity/reject-success',
          response,
          id
        })

        return response
      })
      .catch((error) => {
        throw error
      })
  }
}

export const approveChanges = ({ entity, entityType, changesToSend }) => {
  const { id } = entity
  return (dispatch) => {
    const routeUsed =
      getRouteForEntityDefinition({ entityDefinitions: entityType }) +
      '/' +
      id +
      '/approve'

    return api
      .post(routeUsed, { pendingApprovals: changesToSend })
      .then(async (response) => {
        dispatch({
          type: 'entity/approve-success',
          response,
          id
        })

        return response
      })
      .catch((error) => {
        throw error
      })
  }
}
export const forceActionRun = ({ id, actionId, entityType }) => {
  return () => {
    const routeUsed =
      getRouteForEntityDefinition({ entityDefinitions: entityType }) +
      '/' +
      id +
      '/actions/' +
      actionId +
      '/force'

    return api
      .get(routeUsed)
      .then(async (response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  }
}

export const evaluateActionTruth = ({ id, actionId, entityType }) => {
  return () => {
    const routeUsed =
      getRouteForEntityDefinition({ entityDefinitions: entityType }) +
      '/' +
      id +
      '/actions/' +
      actionId +
      '/status'

    return api
      .get(routeUsed)
      .then(async (response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  }
}
export const showActionHistory = ({ id, entityType }) => {
  return () => {
    const routeUsed =
      getRouteForEntityDefinition({ entityDefinitions: entityType }) +
      '/' +
      id +
      '/actions/history'

    return api
      .get(routeUsed)
      .then(async (response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  }
}
const fetchAuthenticatedBackgroundCheckUrl = (regularUrl) => {
  return () => {
    const route = '/background-check/retrieve'

    const p = api
      .post(route, { report_url: regularUrl }, null)
      .then(async (response) => {
        return response
      })

    return p
  }
}

const listTasks = (args = {}) => {
  const { filter, page, searchText } = args
  return (dispatch) => {
    const route = getRouteForEntityDefinition({
      entityDefinitions: null,
      filter,
      page,
      searchText,
      task: true
    })

    const filterString = getFilterString(filter)

    if (promiseRepository[route + filterString]) {
      return promiseRepository[route + filterString]
    }

    dispatch({
      type: 'entity/task-list-started',
      filterString
    })
    const p = api
      .get(route)
      .then(async (response) => {
        delete promiseRepository[route + filterString]
        await dispatch({
          type: 'entity/task-list-success',
          response,
          filterString
        })

        return response
      })
      .catch((error) => {
        dispatch({
          type: 'entity/task-list-error',
          error
        })

        throw error
      })

    promiseRepository[route + filterString] = p
    return p
  }
}

export const resetLocalEntities = () => {
  return {
    type: 'entity/reset'
  }
}

export const dumpUnsavedEntity = ({ entity }) => {
  console.info('dump unsaved', entity)
  return {
    type: 'entity/dump-unsaved',
    entity
  }
}

export const storeUnsavedEntity = ({ entity }) => {
  console.info('Store unsaved', entity)
  return {
    type: 'entity/store-unsaved',
    entity
  }
}

const abandonEditing = () => {
  return {
    type: 'entity/abandon-editing'
  }
}
const updateEditing = (entity, entityType) => {
  return {
    type: 'entity/update-editing',
    entity,
    entityType
  }
}

const performSearch = (searchText, page = 1) => {
  return (dispatch) => {
    const route =
      '/search?query=' + encodeURIComponent(searchText) + '&page=' + page

    dispatch({
      type: 'entity/search-started',
      data: searchText
    })
    const p = api
      .get(route)
      .then(async (response) => {
        await dispatch({
          type: 'entity/search-success',
          response,
          searchText
        })
        return response
      })
      .catch((error) => {
        dispatch({
          type: 'entity/search-error',
          error
        })

        throw error
      })
    return p
  }
}

const uploadDataFile = (dataFileWrapper, entityType) => {
  const route =
    getRouteForEntityDefinition({ entityDefinitions: entityType }) +
    '/template/import'

  const method = 'post'
  return () => {
    return api[method](route, dataFileWrapper) // api.put('/client/5'), api.post('/client')
      .then(async (response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  }
}

const requestLongRunningJobUpdate = (importId) => {
  return () => {
    const route = `/long-running-job-notes/${importId}`

    const method = 'get'

    const req = api[method](route)
      .then(async (response) => {
        return response
      })
      .catch((e) => {
        throw e
      })

    return req
  }
}

export const viewOrCreateEntity = (
  entityDefinitions,
  args = {},
  createWithParams = {}
) => {
  entityDefinitions = _.castArray(entityDefinitions)
  const {
    filter,
    page,
    searchText,
    due_at,
    from_widget = null,
    detailed = undefined,
    place = undefined
  } = args
  return () => {
    const route = getRouteForEntityDefinition(
      {
        entityDefinitions,
        filter,
        page,
        find_or_create: true,
        searchText,
        due_at,
        detailed,
        place,
        from_widget
      },
      'json'
    )

    const p = api.post(route, createWithParams).then(async (response) => {
      return response
    })

    return p
  }
}

export const listEntity = (
  entityDefinitions,
  args = {},
  type = 'json',
  publicFormEntityType = null,
  publicFormTenantDetails = null
) => {
  let tenantId = null
  let tenantHmac = null

  if (publicFormTenantDetails) {
    ;({ tenantId, tenantHmac } = publicFormTenantDetails)
  }

  entityDefinitions = _.castArray(entityDefinitions)
  const {
    filter,
    page,
    searchText,
    due_at,
    isFullRefresh = false,
    from_widget = null,
    detailed = undefined,
    fromSystemReportId = null,
    fields = undefined,
    files = false,
    sort = null,
    place = undefined,
    per_page = null /* Per page has been overridden below */,
    forceShowLabelInTable,
    forQueue = false
  } = args
  args.per_page =
    per_page ??
    (searchText && searchText.trim().length > 0
      ? ''
      : page === 1
      ? '250'
      : '24')
  return (dispatch) => {
    const dispatchToReducer = type == 'json' //types of csv, pdf, reducer has no interest.
    let route = getRouteForEntityDefinition(
      {
        entityDefinitions,
        filter,
        page,
        searchText,
        due_at,
        detailed,
        fields,
        files,
        sort,
        place,
        from_widget,
        public_form_entity_type: publicFormEntityType,
        public_form_tenant: { tenantId, tenantHmac },
        forceShowLabelInTable,
        fromSystemReportId,
        forQueue
      },
      type
    )
    route += (!route.endsWith('&') ? '&' : '') + 'per_page=' + args.per_page
    let extraArgs = null
    if (place || detailed !== undefined) {
      extraArgs = {}
      if (place) {
        extraArgs['place'] = place
      }
      if (detailed !== undefined) {
        extraArgs['detailed'] = detailed
      }
    }

    const filterString = getFilterString(filter, extraArgs)

    if (promiseRepository[route + filterString]) {
      return promiseRepository[route + filterString]
    }

    dispatchToReducer &&
      dispatch({
        type: 'entity/list-started',
        data: entityDefinitions,
        filterString
      })

    const p = api
      .get(route)
      .then(async (response) => {
        if (!forQueue) {
          dispatchToReducer &&
            (await dispatch({
              type: 'entity/list-success',
              response,
              filterString,
              isSearch: !!args.searchText,
              isFullRefresh: isFullRefresh,
              entityDefinitions
            }))
        }

        delete promiseRepository[route + filterString]
        return response
      })
      .catch((error) => {
        dispatchToReducer &&
          dispatch({
            type: 'entity/list-error',
            filterString,
            entityDefinitions,
            error
          })

        throw error
      })
    promiseRepository[route + filterString] = p
    return p
  }
}

const toggleWatch = ({ entity, entityType }) => {
  return (dispatch) => {
    const { id } = entity

    const route =
      getRouteForEntityDefinition({ entityDefinitions: entityType }) +
      '/' +
      id +
      '/toggleWatch'

    dispatch({
      type: 'entity/watch-toggle-started',
      data: { id }
    })

    return api
      .post(route)
      .then(async (response) => {
        return response
      })
      .catch((error) => {
        dispatch({
          type: 'entity/watch-toggle-error',
          error
        })

        throw error
      })
  }
}

const deleteEntity = ({ id }, entityType) => {
  return (dispatch) => {
    const route =
      getRouteForEntityDefinition({ entityDefinitions: entityType }) + '/' + id

    dispatch({
      type: 'entity/delete-started',
      data: { id, type: entityType }
    })
    return api
      .delete(route)
      .then(async (response) => {
        dispatch({
          type: 'entity/delete-success',
          response,
          id
        })

        return response
      })
      .catch((error) => {
        dispatch({
          type: 'entity/delete-error',
          error
        })

        throw error
      })
  }
}

const showWidget = ({ id, withEntityId }) => {
  return (dispatch) => {
    let route = '/widget/' + id

    if (withEntityId) {
      route = `${route}/entity/${withEntityId}`
    }
    dispatch({
      type: 'entity/widget-show-started',
      data: { id }
    })
    return api
      .get(route)
      .then(async (response) => {
        // console.log(response.data, ' this is the showWidget Response')
        dispatch({
          type: 'entity/widget-show-success',
          response,
          id,
          withEntityId
        })

        return response
      })
      .catch((error) => {
        dispatch({
          type: 'entity/widget-show-error',
          error
        })

        throw error
      })
  }
}

const onPrintEntity = ({ id, type: entityType, pages }) => {
  return async () => {
    const routeUsed =
      getRouteForEntityDefinition({ entityDefinitions: entityType }) +
      '/' +
      id +
      '/print/'
    const payload = {
      // Include any data you want to send in the request body
      // For example, user agent, device type, OS, location, etc.
      userAgent: navigator?.userAgent,
      deviceType: navigator?.platform || Platform.OS,
      operatingSystem: navigator?.userAgentData?.platform,
      pages
    }

    const p = new Promise((resolve) => {
      if (Geolocation) {
        const success = (position) => {
          const latitude = position.coords.latitude
          const longitude = position.coords.longitude
          payload.latitude = latitude
          payload.longitude = longitude
          resolve(payload)
        }
        const error = () => {
          resolve(payload)
        }
        var options = {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        }
        // navigator.geolocation.getCurrentPosition(success, error, options)

        Geolocation.getCurrentPosition(success, error, options)
      } else {
        resolve(payload)
      }
    })
    await p

    return api
      .post(routeUsed, payload)
      .then(async (response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  }
}
const showCustomReport = ({ id, type: entityType, reportType }) => {
  return () => {
    const routeUsed =
      getRouteForEntityDefinition({ entityDefinitions: entityType }) +
      '/' +
      id +
      '/report/' +
      reportType.offline_id

    return api
      .get(routeUsed)
      .then(async (response) => {
        return response
      })
      .catch((error) => {
        throw error
      })
  }
}

export const showEntity = ({ id, type: entityType, route = null }) => {
  return (dispatch) => {
    const routeUsed =
      route ??
      getRouteForEntityDefinition({ entityDefinitions: entityType }) + '/' + id

    if (promiseRepository[routeUsed]) {
      return promiseRepository[routeUsed]
    }

    dispatch({
      type: 'entity/show-started',
      data: { id, type: entityType }
    })

    const promise = api
      .get(routeUsed)
      .then(async (response) => {
        dispatch({
          type: 'entity/show-success',
          response,
          id
        })

        delete promiseRepository[routeUsed]
        return response
      })
      .catch((error) => {
        dispatch({
          type: 'entity/show-error',
          error
        })

        delete promiseRepository[routeUsed]
        throw error
      })

    promiseRepository[routeUsed] = promise
    return promise
  }
}

const sort = (a, b) => a.localeCompare(b)
export const getFilterString = (filter, adhoc = null) => {
  const vals = _.map(filter, (value, key) => {
    if (key == 'detailed') {
      return null
    }
    if (_.isArray(value)) {
      return `filter[custom][]=${encodeURIComponent(
        key +
          '=' +
          value.map((innerVal) => (innerVal?.id ? innerVal?.id : innerVal))
      )}`
    }
    if (typeof value == 'object') {
      let strBuild = []
      Object.keys(value).forEach((objectKey) => {
        if (value[objectKey] !== undefined) {
          strBuild.push(
            `filter[custom][]=${encodeURIComponent(
              key + '.' + objectKey + '=' + value[objectKey]
            )}`
          )
        }
      })
      return strBuild.join('&')
      // return `filter[custom][]=${encodeURIComponent(key + "=" + JSON.stringify(value))}`
    }
    return `filter[custom][]=${encodeURIComponent(
      key + '=' + (value?.id ? value?.id : value)
    )}`
  })

  let str = vals
    .filter((f) => f)
    .sort(sort)
    .join('&')

  if (adhoc) {
    str +=
      '&' +
      _.map(adhoc, (value, key) => {
        return `filter[adhoc]=${encodeURIComponent(key + '=' + value)}`
      })
        .sort(sort)
        .join('&')
  }

  return str
}

const getAddEndpoint = (tool) => {
  return _.get(
    tool,
    'object_data.add_endpoint',
    '/entity/add?type=' + tool?.name
  )
}

export const getAddUrl = (tool, filter, from = null) => {
  const filterString = getPrepopulateString(filter)

  const endpoint = getAddEndpoint(tool)
  const operator = endpoint.indexOf('?') !== -1 ? '&' : '?'
  let url = endpoint + operator + filterString

  if (from) {
    url = url + '&from=' + encodeURIComponent(from)
  }

  url = url + '&mode=add&page=1'

  return url
}
export const getPrepopulateString = (filter) => {
  return _.map(filter, (value, key) => {
    return `field.${encodeURIComponent(key)}=${encodeURIComponent(value)}`
  }).join('&')
}
export const getRouteForEntityDefinition = (
  {
    entityDefinitions,
    filter = null,
    page = null,
    searchText = null,
    due_at = null,
    from_widget = null,
    fields = undefined,
    find_or_create = false,
    task = false,
    detailed = false,
    files = false,
    place = null,
    sort = null,
    public_form_entity_type = null,
    public_form_tenant = null,
    is_public_insert = false,
    forceShowLabelInTable = null,
    fromSystemReportId = null,
    forQueue = false
  },
  type = 'json'
) => {
  entityDefinitions = _.castArray(entityDefinitions)
  const filterString = getFilterString(filter)

  let entityLabel = ''

  let entityTypesQueryParam = ''

  if (public_form_entity_type || is_public_insert) {
    entityLabel += '/public-forms'
  }
  if (task) {
    entityLabel += '/tasks/'
  } else {
    if (entityDefinitions.length < 2) {
      entityLabel += '/entity/' + entityDefinitions[0]?.name
    } else {
      entityLabel += '/entity/list'
      entityTypesQueryParam = entityDefinitions
        .map((e) => 'entityTypeNames[]=' + encodeURIComponent(e.name))
        .join('&')
    }
  }

  if (forQueue) {
    entityLabel += '/queue'
  }

  if (find_or_create) {
    entityLabel = '/entity/' + entityDefinitions[0]?.name + '/find_or_create'
  }

  if (type !== 'json' && type !== 'inline') {
    entityLabel += `/${type}`
  }
  let validDueDate = due_at ? DateTime.fromISO(due_at) : null

  const dueAtParam =
    validDueDate && validDueDate.isValid
      ? `filter[due_at]=${encodeURIComponent(validDueDate.toISODate())}&`
      : ''

  return (
    _.get(entityDefinitions, '[0].object_data.api_endpoint', entityLabel) +
    (page || detailed || filterString || public_form_entity_type ? '?' : '') +
    (page ? `page=${page}&` : '') +
    (public_form_entity_type
      ? `calling_entity_type=${public_form_entity_type.name}&`
      : '') +
    (public_form_tenant?.tenantId
      ? `tenant_id=${public_form_tenant.tenantId}&`
      : '') +
    (public_form_tenant?.tenantHmac
      ? `tenant_id_hmac=${public_form_tenant.tenantHmac}&`
      : '') +
    (detailed ? 'detailed=true&' : '') +
    (files ? 'files=true&' : '') +
    (forceShowLabelInTable ? 'forceShowLabelInTable=true&' : '') +
    (fromSystemReportId ? `fromSystemReportId=${fromSystemReportId}&` : '') +
    (fields ? `fields=${encodeURIComponent(fields.join())}&` : '') +
    (from_widget ? `from_widget=${from_widget}&` : '') +
    (place ? `filter[place]=${encodeURIComponent(place)}&` : '') +
    (sort ? `sort=${sort}&` : '') +
    (searchText
      ? `filter[display_name]=${encodeURIComponent(searchText)}&`
      : '') +
    dueAtParam +
    (entityTypesQueryParam ? `${entityTypesQueryParam}&` : '') +
    (filterString || '')
  )
}

const dumpQueue = () => {
  return (dispatch) => {
    dispatch({
      type: 'entity/dump-queue'
    })
  }
}
const queueEntitySave = (entity, entityType, mode, useTaskLevelValidation) => {
  return (dispatch) => {
    dispatch({
      type: 'entity/queue-entity-for-save',
      data: { entity, entityType, mode, useTaskLevelValidation }
    })
  }
}
const processEntireEntityQueue = () => {
  return async (dispatch, getState) => {
    const state = getState()
    const queue = state.entities.queuedEntitiesForSave
    await Promise.all(
      queue.map((queueItem) => {
        return dispatch(processEntityOnQueue(queueItem.entity, true))
      })
    )

    return true
  }
}
const processEntityOnQueue = (
  _entity,
  asynchronously = false,
  isPublic = false
) => {
  return async (dispatch, getState) => {
    const state = getState()

    const {
      entity,
      entityType,
      mode,
      useTaskLevelValidation
    } = state.entities.queuedEntitiesForSave.find(
      (q) => q.entity.offline_id == _entity.offline_id
    )

    //upload any files for this entity. Files remove themselves from the file upload queue.
    await dispatch(fileActions.tryUploadQueued(entity.id ?? entity.offline_id))

    //removes self from queue on success
    return dispatch(
      upsertEntity(
        entity,
        entityType,
        mode,
        asynchronously,
        isPublic,
        useTaskLevelValidation
      )
    )
  }
}
const removeEntityFromQueue = (entity) => {
  return (dispatch) => {
    return dispatch({
      type: 'entity/remove-queued-entity',
      data: { entity }
    })
  }
}
export const upsertEntity = (
  entity,
  entityDefinition,
  mode,
  asynchronously = false,
  isPublic = false,
  useTaskLevelValidation = false
) => {
  return (dispatch) => {
    dispatch({
      type: 'entity/upsert-started',
      data: { entity, entityDefinition }
    })

    if (!mode) {
      mode = entity.mode
    }

    const method = mode === 'add' ? 'post' : 'put'

    // if the entity def specifies it's own endpoint, use that.
    let route = getRouteForEntityDefinition({
      entityDefinitions: entityDefinition,
      is_public_insert: isPublic
    })

    if (mode === 'add' && useTaskLevelValidation) {
      route = `${route}/task`
    }

    if (mode === 'edit') {
      route = `${route}/${entity.id}`
    }

    return api[method](route, entity) // api.put('/client/5'), api.post('/client')
      .then(async (response) => {
        await dispatch({
          type: 'entity/upsert-success',
          entity,
          response,
          entityDefinition
        })

        await dispatch({
          type: 'entity/dump-unsaved',
          entity
        })

        if (asynchronously) {
          showMessage({
            message: 'A queued entity was saved successfully.',
            type: 'success'
          })
        }
        return response
      })
      .catch((error) => {
        dispatch({
          type: 'entity/upsert-error',
          error,
          entity,
          entityDefinition
        })
        console.error(error)

        throw error
      })
  }
}

const requestTransition = (entity, entityDefinition, transition) => {
  const transitionName = transition.key
  return (dispatch) => {
    dispatch({
      type: 'entity/transition-started',
      data: { entity, transitionName }
    })

    // if the entity def specifies it's own endpoint, use that.
    let route = getRouteForEntityDefinition({
      entityDefinitions: entityDefinition
    })

    route = `${route}/${entity.id}/transition/${transitionName}`

    return api
      .post(route, entity) // api.put('/client/5'), api.post('/client')
      .then(async (response) => {
        await dispatch({
          type: 'entity/transition-success',
          response
        })

        return response
      })
      .catch((error) => {
        dispatch({
          type: 'entity/transition-error',
          error
        })

        throw error
      })
  }
}

export default {
  requestLongRunningJobUpdate,
  fetchAuthenticatedBackgroundCheckUrl,
  viewOrCreateEntity,
  showActionHistory,
  queueEntitySave,
  uploadDataFile,
  dumpQueue,
  showWidget,
  showCustomReport,
  onPrintEntity,
  processEntityOnQueue,
  removeEntityFromQueue,
  processEntireEntityQueue,
  performSearch,
  abandonEditing,
  updateEditing,
  resetLocalEntities,
  listEntity,
  listTasks,
  deleteEntity,
  showEntity,
  clearGeneralError,
  clearFieldErrors,
  requestTransition,
  toggleWatch
}
