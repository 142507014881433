import { useDispatch } from 'react-redux'

import { approveChanges, rejectChanges } from '../store/entity/actions'
import entityActions from '../store/entity/actions'
export const useFieldLevelApprovals = ({
  entity,
  entityType,
  setEntityLoading,
  fetchAndUpdateLocalEntity,
  property
}) => {
  const dispatch = useDispatch()

  const _approveChanges = (property = undefined) => {
    setEntityLoading?.(true)
    const changesToSend = [property]
    return dispatch(approveChanges({ entity, entityType, changesToSend }))
      .then(() => {
        return dispatch(
          entityActions.showEntity({ type: entityType, id: entity.id })
        ).then(() => {
          setEntityLoading?.(false)
        })
      })
      .catch(() => {
        setEntityLoading?.(false)
      })
  }

  const _rejectChanges = () => {
    setEntityLoading?.(true)
    dispatch(
      rejectChanges({ entity, entityType, pendingRejections: [property] })
    )
      .then(() => {
        fetchAndUpdateLocalEntity(entityType, entity.id).then(() => {
          setEntityLoading?.(false)
        })
      })
      .catch(() => {
        setEntityLoading?.(false)
      })
  }

  return {
    approveChanges: _approveChanges,
    rejectChanges: _rejectChanges
  }
}
