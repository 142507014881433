const pieGreen = '#289C1E'
const pieRed = '#AC182D'
const pieYellow = '#F9CA46'
const red100 = '#ac1f2e'
const orange200 = '#ff9933'
const yellow300 = '#FFDE59'
const darkblue700 = '#192e5b'
const blue800 = '#1E65A7'
const lightblue900 = '#7ABAF6'
const darkgreen400 = '#32cd33'
const green500 = '#00743F'
const lightgreen600 = '#1e65a7'

const darkBlue = '#34404E'
const blue = '#527EA1'
const lightBlue = '#DCDCDC'
const lightOrange = '#FCCA46'
const lightGray = '#cccccc'
const lighterGray = '#e8ebed'
const lightestGray = '#ECF0F1'
const skyLighter = '#f2f4f5'
const lightRed = '#e74c3c'
const darkRed = '#9a0223'
const lightPink = '#dfb4bb'
const lighterPink = '#f1dfe4'
const stagingRed = '#b33939'
const redBase = '#BE515B'
const darkGreen = '#009432'
const lightGreen = '#7bed9f'
const labelGray = '#AEAEB2'
const white = 'white'
const redDark = '#AC182D'
const blueLighter = '#C7CFD2'
const blueDarker = '#1a2f3b'
const blueDarkest = '#223E4C'
const mediumGray = '#697386'
const darkGray = '#333'
const yellowDarkest = '#F9CA46'
const yellowLight = '#FCE39C'
const yellowLightest = '#fdebba'
const orangeLighter = '#F8D4C2'
const brightOrange = '#ff9933'
const iconShadow = '#141414'
const red = 'red'

const colors = {
  strokeColors: [
    pieGreen,
    pieRed,
    pieYellow,
    orange200,
    darkblue700,
    red100,
    yellow300,
    blue800,
    lightblue900,
    darkgreen400,
    green500,
    lightgreen600
  ],
  validationErrorBackground: lightRed,
  validationErrorBackgroundDark: redDark,
  validationErrorText: white,
  approvalBackground: brightOrange,
  buttonBackspace: '#ccc',
  controlSelected: yellowDarkest,
  iconShadow,
  lightOrange,
  stagingRed,
  redDark,
  brightOrange,
  lightRed,
  blueDarker,
  lightPink,
  redBase,
  lighterPink,
  mediumGray,
  lightGray,
  lighterGray,
  lightBlue,
  blue,
  blueDarkest,
  yellowDarkest,
  yellowLight,
  yellowLightest,
  lightestGray,
  labelGray,
  darkGreen,
  lightGreen,
  orangeLighter,
  white,
  skyLighter,
  blueLighter,
  darkGray,
  avatarText: white,
  pageHeaderColor: redDark,
  headingBackground: white,
  headingText: blueDarkest,
  headingUnderline: redBase,
  backgroundDark: darkBlue,
  darkBlue,
  background: white,
  iconLight: white,
  moduleIcon: blueDarkest,
  helpText: darkBlue,
  moduleIconTitle: darkBlue,
  buttonPrimaryDark: white,
  buttonSecondaryDark: brightOrange,
  buttonPrimaryTitleDark: darkBlue,
  buttonPrimary: darkBlue,
  buttonSecondary: lightOrange,
  buttonDanger: darkRed,
  buttonSuccess: darkGreen,
  buttonPrimaryTitle: darkBlue,
  inputLabelDark: lightBlue,
  inputLabel: blueDarkest,
  inputTextDark: lightBlue,
  inputText: darkBlue,
  formInputBackground: skyLighter,
  inputBackground: white,
  inputBottomBorder: lightGray,
  required: red,
  iconDark: lightBlue,
  inactiveBackground: lightGray,
  headerBackground: white,
  tabNavBackground: darkBlue,
  tabNavActiveTint: white,
  tabNavInactiveTint: lightGray,
  pageNavigatorBackground: '#778899'
}

export default colors
