// libraries
import React, { useEffect, useMemo, useState } from 'react'
import { View, Platform, ActivityIndicator } from 'react-native'
import { useSelector } from 'react-redux'
import _ from 'lodash'

import { UUIDv4 } from 'uuid-v4-validator'
import { Icon } from 'react-native-elements'
import colors from '../../styles/colors'
import { ListItem, Overlay } from 'react-native-elements'
import { FlatList } from 'react-native'
import OverlayHeader from '../OverlayHeader'
import MSFESAvatar from '../MSFESAvatar'
import { Portal } from 'react-native-paper'
import ListItemAsFieldProps, { InputSelectStyle } from '../ListItemAsFieldProps'
import { SearchBar } from 'react-native-elements'
import MSFESHeading from '../MSFESHeading'
import spacing from '../../styles/spacing'
import MSFESNestedAddEntity from '../MSFESNestedAddEntity'
import { viewMode } from '../../screens/EntityAddScreen'

export const searchStyleProps = {
  inputContainerStyle: [{ backgroundColor: colors.white, marginHorizontal: 8 }],
  containerStyle: { backgroundColor: colors.white, padding: 2 }
}
export const formatDataRows = (
  dataRows,
  keyFieldResolver,
  keyField,
  valueField,
  labelField,
  iconField,
  sortByFunction = (el) => el
) => {
  if (!dataRows) {
    return []
  }
  return _.sortBy(
    dataRows,
    sortByFunction ? sortByFunction : (d) => d?.sort
  ).map((dataItem) => {
    return {
      ...dataItem,

      // display_name: _.get(dataItem, labelField, ''),
      // value: '' + getValue(dataItem, keyFieldResolver, keyField, valueField),
      // id: '' + getKey(dataItem, keyFieldResolver, keyField),
      image: _.get(
        dataItem,
        'path[0]',
        _.get(dataItem, 'SYSTEM_display_picture', null)
      ),
      imageSize:
        _.get(dataItem, 'path[0]', false) == false
          ? 48
          : 96 /* A path is a user generated option. A system display picture is a system thumbnail */,
      icon: iconField ? _.get(dataItem, iconField, undefined) : undefined
    }
  })
}

export const getValue = (dataItem, keyFieldResolver, keyField, valueField) => {
  const objectIsValue = true

  return keyFieldResolver
    ? keyFieldResolver(dataItem)
    : objectIsValue
    ? _.get(dataItem, keyField, null)
    : _.get(dataItem, valueField, null)
}

export const getLabel = (dataItem, labelField, defaultValue = '') => {
  let label = _.get(dataItem, labelField, defaultValue)

  if (!label) {
    label = defaultValue
  }
  return label
}

export const getKey = (dataItem, keyFieldResolver, keyField) => {
  const key = keyFieldResolver
    ? keyFieldResolver(dataItem)
    : '' + _.get(dataItem, keyField, _.get(dataItem, 'id', ''))
  return key
}

export const SelectRHSIcon = () => {
  return (
    <Icon
      name="chevron-down"
      type="font-awesome"
      size={14}
      style={{ marginRight: 16, marginBottom: -2 }}
    />
  )
}

export const simplifyObject = (
  matchingObject,
  keyField = null,
  valueField = null
) => {
  /* We will simplify this object for storage.
    id, entity_type_id, display_name, uri */
  const simplifiedObjectKeys = [
    'id',
    'display_name',
    'entity_type_id',
    'uri', //for entities
    'key',
    'label',
    'offline_id',
    'path',
    'value', //for radio options
    'nfirs_code', //for NFIRS reporting.
    'name',
    'type', // for type selector.
    'field_data.property', //for fields.
    'field_data.type', //for fields.
    'field_data.title', //for fields
    '_event.id', //for virtual entities of recurring events
    '_event.date' //for virtual entities of recurring events
  ]

  // const newObj = _.pickBy(matchingObject, (value, key) => simplifiedObjectKeys.includes(key)); // Object.keys(matchingObject).filter((val) => simplifiedObjectKeys.includes(val))

  let newObj = {}

  simplifiedObjectKeys.forEach((k) => {
    const objectValue = _.get(matchingObject, k, undefined)
    if (objectValue !== undefined) {
      _.set(newObj, k, objectValue)
    }
  })

  if (newObj?.type) {
    newObj.type = simplifyObject(newObj.type)
  }
  if (keyField) {
    _.set(newObj, keyField, _.get(matchingObject, keyField, null))
  }
  if (valueField) {
    _.set(newObj, valueField, _.get(matchingObject, valueField, null))
  }
  return newObj
}

export const onValueChangeWrapped = (
  value,
  dataRows,
  defaultValue,
  keyField,
  keyFieldResolver,
  onChangeText,
  objectIsValue = true,
  callback = null,
  valueField = null,
  simplifyValue = true
) => {
  const userInitiated = value !== defaultValue && value !== ''

  if (objectIsValue) {
    // fetch object and pass back up.
    const matchingObject = dataRows.find((d) => {
      const rowKey = getKey(d, keyFieldResolver, keyField)
      const selectedKey = getKey(value, keyFieldResolver, keyField)
      const isMatch = rowKey == value || rowKey === selectedKey

      return isMatch
    })

    if (matchingObject) {
      const simplifiedObject = simplifyValue
        ? simplifyObject(matchingObject, keyField, valueField)
        : matchingObject

      onChangeText(simplifiedObject, userInitiated)
    } else {
      onChangeText(null, userInitiated)
    }
  } else {
    onChangeText(value, userInitiated)
  }

  callback && callback()
}

export const SelectWithData = (props) => {
  const {
    onEndReached = () => {},
    placeholder,
    value,
    onChangeText,
    style,
    dataRows = [],
    editable = true,
    valueField = 'id',
    linkStyle = false,
    keyField = 'id',
    labelField = 'display_name',
    iconField = null,
    objectIsValue = true,
    keyFieldResolver = false,
    onDropdownStatusChanged = null,
    groupByFunction = null,
    simplifyObject = true,
    loading = false
  } = props

  const [searchValue, setSearchValue] = useState('')

  const [showNestedView, setShowNestedView] = useState(false)

  useEffect(() => {
    if (
      props.defaultValue &&
      !props.value &&
      props.data?.entity?.mode === 'add'
    ) {
      // this is a new record with a default value (e.g. calculated).
      // we probably need to propagate it up.
      if (typeof props.defaultValue === 'object') {
        if (
          getKey(selectedItem, keyFieldResolver, keyField) !==
          getKey(props.defaultValue, keyFieldResolver, keyField)
        ) {
          onChangeText(
            {
              [keyField]: getKey(props.defaultValue, keyFieldResolver, keyField)
            },
            false
          )
        }
      } else {
        onChangeText({ [keyField]: props.defaultValue }, false)
      }
    }
  }, [props.defaultValue?.key])

  const dataFormatted = useMemo(() => {
    return formatDataRows(
      dataRows,
      keyFieldResolver,
      keyField,
      valueField,
      labelField,
      iconField
    )
  }, [dataRows, keyFieldResolver, keyField, valueField, labelField, iconField])

  const dataFiltered = useMemo(() => {
    const searchValueLower = searchValue.toLowerCase()

    const dataFormattedSafe = dataFormatted ?? []
    let filtered = [
      { id: null, value: null, [labelField]: '(No Selection)' },
      ...dataFormattedSafe.filter((f) =>
        searchValueLower
          ? (getLabel(f, labelField) ?? '')
              .toLowerCase()
              .includes(searchValueLower)
          : true
      )
    ]

    return filtered
  }, [dataFormatted, searchValue])

  const [showDropDown, setShowDropDown] = useState(false)

  useEffect(() => {
    onDropdownStatusChanged && onDropdownStatusChanged(showDropDown)
  }, [showDropDown])

  const selectedItem = useMemo(() => {
    const selectedKey = getKey(value, keyFieldResolver, keyField)
    const matchingObject = dataFormatted?.find((d) => {
      const rowKey = getKey(d, keyFieldResolver, keyField)

      const isMatch = rowKey == value || rowKey == selectedKey

      return isMatch
    })

    return matchingObject
  }, [dataFormatted, value])

  // is there an ID supplied that is a guid?
  const isGuid = useMemo(() => {
    return props.defaultValue?.id && UUIDv4.validate(props.defaultValue?.id)
  }, [props.defaultValue])

  const placeholderText = editable
    ? placeholder
      ? placeholder
      : 'Select an item'
    : isGuid
    ? '(Unsaved)'
    : '(None)'

  const searchTextChanged = (searchValue) => {
    setSearchValue(searchValue)
  }
  const onSearchCancel = () => {
    searchTextChanged('')
  }

  const dropdownOverlay = useMemo(() => {
    return (
      showDropDown && (
        <Overlay
          isVisible={true}
          onBackdropPress={() => setShowDropDown(false)}
          overlayStyle={{ padding: 0, margin: 0 }}
        >
          <OverlayHeader>{placeholderText}</OverlayHeader>
          {dataFormatted?.length > 5 && (
            <View>
              <SearchBar
                autoFocus={true}
                placeholder="Start Typing"
                onClear={onSearchCancel}
                lightTheme={true}
                {...searchStyleProps}
                value={searchValue}
                onChangeText={searchTextChanged}
              />
            </View>
          )}
          <FlatList
            style={{ maxHeight: 400, minWidth: 200 }}
            keyboardShouldPersistTaps={'always'}
            keyExtractor={(item) => {
              const key = '' + getKey(item, keyFieldResolver, keyField)
              return key
            }}
            onEndReached={onEndReached}
            onEndReachedThreshold={2}
            data={dataFiltered}
            renderItem={({ item, index }) => {
              const needsHeader =
                (groupByFunction && index == 0) ||
                (index > 0 &&
                  groupByFunction &&
                  groupByFunction(dataFiltered[index - 1]) !==
                    groupByFunction(dataFiltered[index]))

              const listItem = (
                <ListItem
                  key={index.toString()}
                  onPress={() =>
                    onValueChangeWrapped(
                      item,
                      dataRows,
                      props.defaultValue,
                      keyField,
                      keyFieldResolver,
                      onChangeText,
                      objectIsValue,
                      () => setShowDropDown(false),
                      valueField,
                      simplifyObject
                    )
                  }
                >
                  {item?.image ? (
                    <MSFESAvatar
                      showLabel={false}
                      rounded={false}
                      size={item.imageSize}
                      source={item.image}
                    />
                  ) : null}
                  {item?.icon ? (
                    <MSFESAvatar
                      showLabel={false}
                      icon={{ name: item[iconField], type: 'font-awesome' }}
                    />
                  ) : null}
                  <ListItem.Content>
                    <ListItem.Title>
                      {getLabel(item, labelField)}
                    </ListItem.Title>
                  </ListItem.Content>
                  {getKey(item, keyFieldResolver, keyField) ===
                    getKey(selectedItem, keyFieldResolver, keyField) && (
                    <ListItem.Chevron
                      name="check"
                      type="font-awesome"
                      size={14}
                      style={{ marginBottom: -2 }}
                      color={colors.moduleIcon}
                    />
                  )}
                </ListItem>
              )

              if (needsHeader) {
                const Header = (
                  <MSFESHeading
                    key={'Header_' + index}
                    textStyle={{ marginHorizontal: spacing.m2 }}
                  >
                    {groupByFunction(dataFiltered[index])}
                  </MSFESHeading>
                ) // <View><Text></Text></View>
                return [Header, listItem]
              } else {
                return listItem
              }
            }}
          ></FlatList>
          {loading && (
            <View style={{ paddingVertical: spacing.m0_5 }}>
              <ActivityIndicator />
            </View>
          )}
        </Overlay>
      )
    )
  }, [dataFormatted, labelField, keyField, showDropDown, searchValue])

  const onPress = editable
    ? () => editable && setShowDropDown(true)
    : () => {
        if (selectedItem && selectedItem.uri) {
          // just in case this isn't exactly an entity, but has an entity.
          if (!selectedItem.entity_type) {
            if (selectedItem.tenant_record) {
              setShowNestedView(selectedItem.tenant_record)
              return
            }
          }
          setShowNestedView(selectedItem)
          // linkTo(selectedItem.uri);
        }
      }

  const selectComponent = useMemo(() => {
    if (props.selectComponent) {
      return props.selectComponent({ onPress })
    }
    return null
  }, [onPress, props.selectComponent])

  const clickableLabelStyle = linkStyle
    ? {
        textDecorationLine: 'underline',
        color: colors.redDark
      }
    : null

  const isClickableToEntity = !editable && selectedItem?.uri
  return (
    <View style={[style]}>
      <View>
        <View>{selectComponent}</View>
        {!selectComponent && (
          <ListItem
            bottomDivider
            containerStyle={ListItemAsFieldProps.containerStyle}
            {...ListItemAsFieldProps.touchableProps}
            onPress={onPress}
          >
            {selectedItem?.image ? (
              <MSFESAvatar showLabel={false} source={selectedItem.image} />
            ) : null}
            {selectedItem?.icon && (
              <MSFESAvatar
                showLabel={false}
                icon={{ name: selectedItem.icon, type: 'font-awesome' }}
              />
            )}
            <ListItem.Content>
              <ListItem.Title
                style={[
                  InputSelectStyle,
                  isClickableToEntity && clickableLabelStyle
                ]}
              >
                {selectedItem
                  ? getLabel(selectedItem, labelField)
                  : placeholderText}
              </ListItem.Title>
            </ListItem.Content>
            {editable && (
              <ListItem.Chevron
                name="chevron-down"
                type="font-awesome"
                size={14}
                style={{ marginBottom: -2 }}
                color={colors.moduleIcon}
              />
            )}
          </ListItem>
        )}

        {Platform.OS === 'web' && (
          <>{showDropDown && <Portal>{dropdownOverlay}</Portal>}</>
        )}
        {Platform.OS !== 'web' && <>{showDropDown && dropdownOverlay}</>}
        {showNestedView && (
          <MSFESNestedAddEntity
            entityType={showNestedView.entity_type}
            mode={viewMode}
            id={showNestedView.id}
            onDismiss={() => setShowNestedView(false)}
          />
        )}
      </View>
    </View>
  )
}

export const SelectField = (props) => {
  const { fieldsSupplied, fieldTypeFilter = null } = props

  // data.field.field_data.params.sourceEntityField.field_data.property
  const fieldListRoot = useSelector((state) => {
    const entity = props.data?.entity

    const fieldOfEntityTypeSelected =
      props.data?.field?.field_data?.params?.sourceEntityField?.field_data
        ?.property
    const entityTypeIdSelected =
      props.data?.entity?.[fieldOfEntityTypeSelected]?.id

    return state.entityTypes.data.find(
      (entityType) =>
        (entity && entityType.id === entityTypeIdSelected) ||
        (!entityTypeIdSelected &&
          (entityType.id === entity?.id || entityType.name === entity?.name))
    )
  })

  const fields = fieldsSupplied
    ? fieldsSupplied
    : _.get(fieldListRoot, 'fields', [])

  const dataRows = [
    ...fields,
    {
      id: 'tenant_id',
      field_data: { title: 'Tenant', property: 'tenant_id', id: 'tenant_id' }
    },
    { id: 'id', field_data: { title: 'ID', property: 'id', id: 'id' } }
  ].filter((f) =>
    fieldTypeFilter ? fieldTypeFilter.includes(f.field_data.type) : true
  )

  return (
    <SelectWithData
      {...props}
      dataRows={dataRows}
      labelField={'field_data.title'}
      keyField={'id'}
      valueField={props.valueField ? props.valueField : 'id'}
      value={props.value ?? props.defaultValue}
      onChangeText={(value, userInitiated) => {
        props.onChangeText && props.onChangeText(value, userInitiated)
      }}
    />
  )
}

export const SelectIcon = (props) => {
  const data = useMemo(() => {
    const icons = require('@expo/vector-icons/build/vendor/react-native-vector-icons/glyphmaps/FontAwesome.json')

    return _.orderBy(
      _.map(icons, (value, key) => {
        return { name: key, id: key }
      }),
      ['name']
    )
  }, [])

  return (
    <SelectWithData
      {...props}
      onChangeText={(value, userInitiated) => {
        props.onChangeText &&
          props.onChangeText(value?.name ?? null, userInitiated)
      }}
      dataRows={data}
      keyField="name"
      valueField="name"
      labelField="name"
      iconField="name"
      value={props.defaultValue}
    />
  )
}

export const getCurrentUser = () => {
  const current_user = useSelector((state) => {
    return _.get(state, 'users.user')
  })
  return current_user
}

export default SelectWithData
