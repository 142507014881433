import { evaluate } from '../../helpers/jsonlogic'
import memoize from 'lodash/memoize'

export const evaluateRule = memoize((rule, values, valueWhenError = false) => {
  let result = valueWhenError

  // manipulate the rule:
  // 'field.requestor' should be {"var":"field.requestor"}.
  let ruleText = JSON.stringify(rule)
  if (!ruleText) {
    return valueWhenError
  }

  if (values.client?.id) {
    values.client.id = '' + values.client.id
  }
  const expansionKeys = ['field']

  expansionKeys.forEach((expansionKey) => {
    if (values?.[expansionKey]) {
      Object.keys(values?.[expansionKey]).map((key) => {
        ruleText = ruleText
          .split(`"${expansionKey}.${key}"`)
          .join(`{"var": "${expansionKey}.${key}"}`)
      })

      rule = JSON.parse(ruleText)
    }
  })

  try {
    result = evaluate(rule, values)
  } catch (e) {
    console.warn('jsonlogic error', e)
    return valueWhenError
  }

  return result
})
