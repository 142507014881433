import React from 'react'

import WithHeader from '../../components/WithHeader'
import { View } from 'react-native'

import GlobalLoading from '../../components/GlobalLoading'
import MSFESHeading from '../../components/MSFESHeading'
import DataTableWrapper from '../EntityListScreenHelpers/DataTableWrapper'
import { useGetFriendlyFilter } from './useGetFriendlyFilter'
import { useFetchEntitiesList } from './useFetchEntitiesList'
import { useGetReportEntity } from './useGetReportEntity'
import { FilterParametersList } from './FilterParametersList'
import { styles } from './ShowReportsScreenStyles'
import spacing from '../../styles/spacing'
import MSFESButton from '../../components/MSFESButton'
import { useCanExportToCSV } from './useCanExportToCSV'

export default function ShowReportsScreen({ navigation, route }) {
  const { reportEntity, reportEntityType, reportFields } = useGetReportEntity({
    route,
    navigation
  })

  const { friendlyFilter, filterPath, filter } = useGetFriendlyFilter({
    reportEntity
  })

  const { loadMoreItems, onRefresh, refreshing, meta } = useFetchEntitiesList({
    reportEntityType,
    filter,
    filterPath
  })

  const { exportToCSV, reportPreparing } = useCanExportToCSV({
    item: reportEntity,
    forceShowLabelInTable: true
  })

  const isReady = !!(reportEntity && filterPath && reportEntityType)

  const isViewOnly = reportEntity?.view_only_no_click_through

  return (
    <WithHeader title="" leftButtonType="back" navigation={navigation}>
      <View style={styles.container}>
        {reportPreparing && <GlobalLoading />}
        {!isReady && <GlobalLoading />}
        {isReady && (
          <WithHeader
            title={'Report: ' + reportEntity.display_name}
            splitViewShowHeader={true}
            rightButtonAction={null}
          >
            <View
              style={{
                flexDirection: 'row',
                padding: spacing.m0_5,
                justifyContent: 'flex-end'
              }}
            >
              <View style={{ paddingRight: spacing.m0_5 }}>
                <MSFESButton
                  title="Export"
                  variant="outline"
                  onPress={exportToCSV}
                />
              </View>
            </View>
            <View style={styles.contentContainer}>
              <MSFESHeading>Filter parameters</MSFESHeading>
              <View style={styles.parametersWrapper}>
                <FilterParametersList
                  friendlyFilter={friendlyFilter}
                  meta={meta}
                  tenant_id={reportEntity.tenant_id}
                />
              </View>

              <MSFESHeading>Matching Records</MSFESHeading>
              <View style={styles.tableWrapper}>
                <DataTableWrapper
                  entityType={reportEntityType}
                  reportEntity={reportEntity}
                  filterToFields={reportFields}
                  shouldSplit={false}
                  sourceKey={filterPath}
                  refreshing={refreshing}
                  onRefresh={onRefresh}
                  loadMoreItems={loadMoreItems}
                  isForCustomReport={true}
                  canNavigate={!isViewOnly}
                  forceShowLabelInTable={true}
                />
              </View>
            </View>
          </WithHeader>
        )}
      </View>
    </WithHeader>
  )
}
